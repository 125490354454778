import React from 'react'
import { useSelector } from 'react-redux'
import fusionOidc from 'src/fusionOidc'
import {
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilList, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const AppHeaderDropdown = () => {
  const user = useSelector((state) => state.user)
  if (!user) {
    return (
      <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" caret={false}>
          <CIcon icon={cilList} size="lg" />
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
          <CDropdownItem onClick={fusionOidc.login}>
            <CIcon icon={cilUser} className="me-2" />
            Log in
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    )
  }
  const profileUrl = fusionOidc.oidcSettings.authority + '/ui/console/users/me'
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" caret={false}>
        <CIcon icon={cilList} size="lg" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {/*
        <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilBell} className="me-2" />
          Updates
          <CBadge color="info" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilEnvelopeOpen} className="me-2" />
          Messages
          <CBadge color="success" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilTask} className="me-2" />
          Tasks
          <CBadge color="danger" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilCommentSquare} className="me-2" />
          Comments
          <CBadge color="warning" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        */}
        <CDropdownHeader className="bg-light fw-semibold py-2">{user.name}</CDropdownHeader>
        <CDropdownItem onClick={fusionOidc.logout}>
          <CIcon icon={cilUser} className="me-2" />
          Log out
        </CDropdownItem>
        <CDropdownItem href={profileUrl}>
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        {/*
        <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilSettings} className="me-2" />
          Settings
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilCreditCard} className="me-2" />
          Payments
          <CBadge color="secondary" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilFile} className="me-2" />
          Projects
          <CBadge color="primary" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem href="#">
          <CIcon icon={cilLockLocked} className="me-2" />
          Lock Account
        </CDropdownItem>
        */}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
