import React from 'react'
import { PropTypes } from 'prop-types'
import {
  CListGroup,
  CListGroupItem,
  CCard,
  CCardHeader,
  CCardBody,
  CCardTitle,
  CRow,
  CCol,
} from '@coreui/react'
import axios from 'axios'
import store from 'src/store'

import ClaimsReportingInfo from './ClaimsReporting'

const Message = (props) => {
  const { msgTitle, msgBody } = props
  return (
    <CCard>
      <CCardHeader>
        <CCardTitle>{msgTitle}</CCardTitle>
      </CCardHeader>
      <CCardBody>{msgBody}</CCardBody>
    </CCard>
  )
}

Message.propTypes = {
  msgTitle: PropTypes.string,
  msgBody: PropTypes.string,
}

const PolicyCard = (props) => {
  const { policy } = props
  console.log('PolicyCard policy:', policy)
  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>{policy.productName} Summary</CCardHeader>
        <CCardBody>
          <CCardTitle>
            <em>{policy.productName}</em>
          </CCardTitle>
          <CListGroup flush>
            <CListGroupItem>
              <strong>Named Insured:</strong> {policy.insuredName}
            </CListGroupItem>
            <CListGroupItem>
              <strong>Policy Number:</strong> {policy.number}
            </CListGroupItem>
            {/*
            <CListGroupItem>
              <strong>Program:</strong> {policy.productName}
            </CListGroupItem>
            <CListGroupItem>
              <strong>Policy Status:</strong> {policy.status}
            </CListGroupItem>
            */}
            <CListGroupItem>
              <strong>Policy Period:</strong> {new Date(policy.start_date).toLocaleDateString()}{' '}
              <em>through</em> {new Date(policy.end_date).toLocaleDateString()}
            </CListGroupItem>
            <CListGroupItem>
              <strong>Aggregate Limit:</strong> {policy.limit}
            </CListGroupItem>
            <CListGroupItem>
              <strong>Retention:</strong> {policy.deductible}
            </CListGroupItem>
            <CListGroupItem>
              <strong>Coverages:</strong>
              <CRow className="mt-1">
                <CCol className="mx-5 border rounded">
                  <CCardHeader border text-center>
                    Base Form
                  </CCardHeader>
                  <CListGroup flush>
                    {policy.coverages
                      .filter((c) => c.coverageType === 'UnderlyingLimit')
                      .map((c) => (
                        <CListGroupItem key={c.id}>
                          <strong>{c.displayName}</strong>
                        </CListGroupItem>
                      ))}
                  </CListGroup>
                </CCol>
                <CCol className="mx-5 border rounded">
                  <CCardHeader border text-center>
                    Endorsements
                  </CCardHeader>
                  <CListGroup flush>
                    {policy.coverages
                      .filter((c) => c.coverageType === 'AdditionalCoverage')
                      .map((c) => (
                        <CListGroupItem key={c.id}>
                          <strong>{c.displayName}</strong>
                        </CListGroupItem>
                      ))}
                  </CListGroup>
                </CCol>
              </CRow>
            </CListGroupItem>
          </CListGroup>
          {/*
          <CCardTitle>
            <em>Policy Documents:</em>
          </CCardTitle>
          <CListGroup flush>
            <CListGroupItem>
              <strong>Policy:</strong>
              <a href="#{policy.policy_link}">Coming Soon</a>
            </CListGroupItem>
            <CListGroupItem>
              <strong>Application:</strong>
              <a href="#{policy.application_link}">Coming Soon</a>
            </CListGroupItem>
            <CListGroupItem>
              <strong>Scan Results:</strong>
              <a href="#{policy.scan_link}">Coming Soon</a>
            </CListGroupItem>
          </CListGroup>
          */}
          <hr />
          <h3>Claims Reporting Information</h3>
          <ClaimsReportingInfo productCode={policy.productCode} />
        </CCardBody>
      </CCard>
    </>
  )
}

PolicyCard.propTypes = {
  policy: PropTypes.object,
  index: PropTypes.number,
  total: PropTypes.number,
}

export const getPolicies = () => {
  const token = store.getState().token
  const policy = store.getState().chainThatPolicy
  const lastPolicyFetchTime = store.getState().chainThatPolicyFetchTime
  const msgTitle = store.getState().chainThatMsgTitle
  const msgBody = store.getState().chainThatMsgBody

  const fetch_timeout = 10000

  if (lastPolicyFetchTime > new Date().getTime() - fetch_timeout) {
    console.log(`Policy recently fetched.  policies?.length:"${policy?.length}"`)
    console.log(`    msgTitle:"${msgTitle}"  msgBody:"${msgBody}"`)
  } else if (!token?.access_token) {
    console.warn("No token, won't fetch policy")
    if (msgTitle !== 'No Access') {
      store.dispatch({
        type: 'set',
        chainThatMsgTitle: 'No Access',
        chainThatMsgBody: 'Please login to view policy information',
      })
    }
  } else {
    const baseHost = window.location.host.split('.').slice(1).join('.')
    axios
      .get(`https://hub-api.${baseHost}/v1/policies`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      })
      .then((response) => {
        console.log('got policy', response)
        store.dispatch({
          type: 'set',
          chainThatPolicy: response.data,
          chainThatPolicyFetchTime: new Date().getTime(),
          chainThatMsgTitle: '',
          chainThatMsgBody: '',
        })
      })
      .catch((error) => {
        console.log('Error getting policy', error)
        if (msgTitle !== 'Error Getting Policy') {
          store.dispatch({
            type: 'set',
            chainThatMsgTitle: 'Error Getting Policy',
            chainThatMsgBody: 'Something went wrong.  Please try again later.',
          })
        }
      })
  }
}

const Policy = () => {
  const policy = store.getState().chainThatPolicy
  const msgTitle = store.getState().chainThatMsgTitle
  const msgBody = store.getState().chainThatMsgBody

  getPolicies()
  console.debug(`    policies?.length:"${policy?.length}"`)
  console.debug(`    msgTitle:"${msgTitle}"  msgBody:"${msgBody}"`)
  return (
    <>
      {msgTitle && msgBody && <Message msgTitle={msgTitle} msgBody={msgBody} />}
      {policy ? (
        policy.length < 1 ? (
          <Message msgTitle="No Policy Found" msgBody="Please contact your broker." />
        ) : (
          policy.map((p, i) => <PolicyCard policy={p} index={i} total={policy.length} key={p.id} />)
        )
      ) : (
        <Message msgTitle={'Fetching Policy Data...'} msgBody={'...'} />
      )}
    </>
  )
}

export default Policy
