import React from 'react'
import { PropTypes } from 'prop-types'

const ClaimsReportingInfo = (props) => {
  const { productCode } = props
  console.log('ClaimsReportingInfo productCode:', productCode)

  if (productCode === 'FUBO') {
    console.warn('ClaimsReportingInfo: None for Boost product FUBO')
  } else if (productCode === 'CYLB') {
    console.debug('ClaimsReportingInfo: Obsidian "Fusion Cyber 360" product')
    return (
      <>
        <p className="mb-1">
          <strong>Notice of Claim or Loss:</strong>
        </p>
        <p className="mb-0">CCMSI Claims Reporting</p>
        <p className="mb-0">P.O. Box 4998</p>
        <p className="mb-1">Greenwood Village, CO 80155</p>
        <p className="font-weight-bold">
          Email: <a href="mailto:fusioncyberclaims@tnwinc.com">fusioncyberclaims@tnwinc.com</a>
        </p>
        <p className="mb-1">
          <strong>24/7 Breach Reporting Team:</strong>
        </p>
        <p className="mb-0 font-weight-bold">
          Phone: <a href="tel:8448566906">844-856-6906</a>
        </p>
        <p className="mb-0 font-weight-bold">
          Email: <a href="mailto:fusioncyberclaims@tnwinc.com">fusioncyberclaims@tnwinc.com</a>
        </p>
      </>
    )
  } else {
    console.warn('ClaimsReportingInfo: productCode not recognized:', productCode)
  }

  return (
    <>
      <p className="mb-1">
        <strong>Please contact your broker for claims reporting information.</strong>
      </p>
    </>
  )
}

ClaimsReportingInfo.propTypes = {
  productCode: PropTypes.string,
}

export default ClaimsReportingInfo
