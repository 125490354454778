import React from 'react'
import { CRow, CCard, CCardHeader, CCardBody } from '@coreui/react'

import ClaimsReportingInfo from '../policy/ClaimsReporting'

import store from 'src/store'
import { getPolicies } from '../policy/Policy'

const Contact = () => {
  const msgTitle = store.getState().chainThatMsgTitle
  const msgBody = store.getState().chainThatMsgBody

  const policy_state = store.getState().chainThatPolicy
  let policy = []
  if (policy_state === undefined) {
    console.debug('    fetching policies')
    getPolicies()
  } else {
    policy = policy_state
    console.debug(`    policies?.length:"${policy?.length}"`)
  }

  console.debug(`    msgTitle:"${msgTitle}"  msgBody:"${msgBody}"`)
  const isodate = (d) => new Date(d).toISOString().slice(0, 10)
  const today = isodate(new Date())
  return (
    <>
      <CCard className="mb-4">
        <CCardHeader>Contact Us</CCardHeader>
        <CCardBody>
          <CRow>
            {policy.length > 0 && <h2>For Claims</h2>}
            {policy
              .filter((p) => isodate(p.start_date) <= today && isodate(p.end_date) >= today)
              .map((p, i) => (
                <CCard className="mx-3 mb-3" key={i}>
                  {i > 0 && <hr />}
                  <h4>{p.productName}</h4>
                  <ClaimsReportingInfo productCode={p.productCode} />
                </CCard>
              ))}
            <hr />
            <h2>For Policy Questions</h2>
            <p>
              Please contact your broker directly. We cannot directly take action or answer
              questions about your insurance policy.
            </p>
            <hr />
            <h2>Contact Us</h2>
            <p>
              For questions related to this website, its contents and services, please contact us in
              one of the following ways:
            </p>
            <p className="mx-3">
              Email: <a href="mailto:hub-contact@fusionmga.com">hub-contact@fusionmga.com</a>
            </p>
            <p className="mx-3">
              Phone: <a href="tel:1-855-639-4427">1-855-639-4427</a>
            </p>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  )
}

export default Contact
