import axios from 'axios'
import store from './store'
import env from 'react-dotenv'

let disco = localStorage.fusionOidcDisco ? JSON.parse(localStorage.fusionOidcDisco) : null
let auth = localStorage.fusionOidcAuth ? JSON.parse(localStorage.fusionOidcAuth) : null
let token = localStorage.fusionOidcToken ? JSON.parse(localStorage.fusionOidcToken) : null
let oidcSettings = {
  authority: env.REACT_OIDC_AUTHORITY,
  clientId: env.REACT_OIDC_CLIENT_ID,
  redirect_uri: window.location.origin,
  scope: 'openid email profile urn:zitadel:iam:user:metadata',
  post_logout_redirect_uri: window.location.origin,
}
console.debug('oidcSettings', oidcSettings)
let hooks = {
  preDiscover: async () => {
    console.log('preDiscover')
  },
  errDiscover: async (error) => {
    console.log('errDiscover', error)
  },
  postDiscover: async () => {
    console.log('postDiscover', disco)
  },

  preAuth: async () => {
    console.log('preAuth')
  },
  errAuth: async (error) => {
    console.log('errAuth', error)
  },
  postAuth: async () => {
    console.log('postAuth', auth)
  },

  preToken: async () => {
    console.log('preToken')
  },
  errToken: async (error) => {
    console.log('errToken', error)
  },
  postToken: async () => {
    console.log('postToken', token)
  },

  preRefresh: async () => {
    console.log('preRefresh')
  },
  errRefresh: async (error) => {
    console.log('errRefresh', error)
  },
  postRefresh: async () => {
    console.log('postRefresh', token)
  },

  preUser: async () => {
    console.log('preUser')
  },
  errUser: async (error) => {
    console.log('errUser', error)
  },
  postUser: async () => {
    console.log('postUser', store.getState().user)
  },
}

async function startIt() {
  await doDisco()
  await doAuthStart()
}

async function endIt() {
  await doDisco()
  await doAuthEnd()
  await doToken()
  await doUser()
}

async function logout() {
  console.debug('START logout')
  if (!disco || !disco.end_session_endpoint || !oidcSettings) {
    console.error('ERROR logout', 'invalid state')
    console.error('disco', disco)
    console.error('oidcSettings', oidcSettings)
    if (!!hooks.errAuth) {
      console.error('logout errAuth')
      hooks.errAuth('invalid state during logout')
    }
    throw Error('invalid state during logout')
  }
  localStorage.removeItem('fusionOidcDisco')
  localStorage.removeItem('fusionOidcAuth')
  localStorage.removeItem('fusionOidcToken')
  store.dispatch({ type: 'set', user: null })
  /***
   * This would invalidate the auth token, but we just want to trigger a
   *   refresh.  The UX is better if we don't log the user out of the identity
   *   provider.
  disco.end_session_endpoint +
    `?post_logout_redirect_uri=${oidcSettings.post_logout_redirect_uri}` +
    `&client_id=${oidcSettings.clientId}`
  console.debug('logout url', url)
  window.location.href = url
  */
}

async function doDisco() {
  console.debug('START doDisco')
  if (!!hooks.preDiscover) {
    console.debug('doDisco preDiscover')
    hooks.preDiscover()
  }
  return axios
    .get(oidcSettings.authority + '/.well-known/openid-configuration')
    .then((response) => {
      disco = response.data
      localStorage.fusionOidcDisco = JSON.stringify(disco)
      if (!!hooks.postDiscover) {
        console.debug('doDisco postDiscover')
        hooks.postDiscover()
      }
      console.debug('DONE doDisco', disco)
      return disco
    })
    .catch((error) => {
      console.error('ERROR doDisco', error)
      if (!!hooks.errDisco) {
        console.debug('doDisco errDisco')
        hooks.errDisco(error)
      }
      throw error
    })
}

async function doAuthStart() {
  console.debug('START doAuthStart')
  if (!disco || !disco.authorization_endpoint || !oidcSettings) {
    console.error('ERROR doAuthStart', 'invalid state')
    console.error('disco', disco)
    console.error('oidcSettings', oidcSettings)
    if (!!hooks.errAuth) {
      console.error('doAuthStart errAuth')
      hooks.errAuth('invalid state')
    }
    throw Error('invalid state')
  }
  var state = __randomString()
  var { code_verifier, code_challenge } = await __pkce()
  auth = { code_verifier, code_challenge, state }
  localStorage.fusionOidcAuth = JSON.stringify(auth)
  var url = disco.authorization_endpoint
  url += '?response_type=code'
  url += `&client_id=${oidcSettings.clientId}`
  url += `&redirect_uri=${oidcSettings.redirect_uri}`
  url += `&scope=${oidcSettings.scope}`
  url += `&state=${state}`
  url += `&code_challenge=${code_challenge}`
  url += '&code_challenge_method=S256'
  console.debug('doAuthStart url', url)
  if (!!hooks.preAuth) {
    console.debug('doAuthStart preAuth')
    hooks.preAuth()
  }
  window.location.href = url
}

async function doAuthEnd() {
  const response = __parse_uri_query()
  if (!response || !response.code || !response.state) {
    console.warn('WARNING doAuthEnd', 'invalid response - skipping')
    console.warn('response', response)
    console.warn('auth state', auth)
    console.warn('WARNING doAuthEnd', 'invalid response - skipping')
    return
  }
  if (!auth || !auth.state || response.state !== auth.state) {
    console.error('ERROR doAuthEnd state missing or mismatch')
    console.error('ERROR doAuthEnd state', auth)
    console.error('ERROR doAuthEnd response', response)
    if (!!hooks.errAuth) {
      console.debug('doAuthEnd errAuth')
      hooks.errAuth('state missing or mismatch')
    }
    throw Error('state missing or mismatch')
  }
  auth = { ...auth, ...response }
  localStorage.fusionOidcAuth = JSON.stringify(auth)
  if (!!hooks.postAuth) {
    console.debug('doAuthEnd postAuth')
    hooks.postAuth()
  }
  console.debug('DONE doAuthEnd', auth)
}

async function doToken() {
  console.debug('START doToken')
  if (!auth || !auth.code || !auth.code_verifier || !disco.token_endpoint) {
    console.error('ERROR doToken no code or code_verifier or token_endpoint')
    if (!!hooks.errToken) {
      console.debug('doToken errToken')
      hooks.errToken('no code or code_verifier or token_endpoint')
    }
    throw Error('no code or code_verifier or token_endpoint')
  }
  if (!!hooks.preToken) {
    console.debug('doToken preToken')
    hooks.preToken()
  }
  return axios
    .get(disco.token_endpoint, {
      params: {
        code: auth.code,
        grant_type: 'authorization_code',
        redirect_uri: oidcSettings.redirect_uri,
        code_verifier: auth.code_verifier,
        client_id: oidcSettings.clientId,
      },
    })
    .then((response) => {
      token = response.data
      localStorage.fusionOidcToken = JSON.stringify(token)
      var user = store.getState().user
      if (user && !user.loggedIn) {
        user.loggedIn = true
      }
      store.dispatch({ type: 'set', token, user })
      setTimeout(doRefresh, token.expires_in * 0.9 * 1000)
      if (!!hooks.postToken) {
        console.debug('doToken postToken')
        hooks.postToken()
      }
      console.debug('DONE doToken', token)
      return token
    })
    .catch((error) => {
      console.error('ERROR doToken', error)
      var user = store.getState().user
      if (user && user.loggedIn) {
        user.loggedIn = false
        store.dispatch({ type: 'set', user })
      }
      if (!!hooks.errToken) {
        console.debug('doToken errToken')
        hooks.errToken(error)
      }
      throw error
    })
}

async function doRefresh() {
  console.debug('START doRefresh')
  if (!token || !token.refresh_token || !disco.refresh_endpoint) {
    console.error('ERROR doRefresh no refresh_token or _endpoint')
    if (!!hooks.errRefresh) {
      console.debug('doRefresh errRefresh')
      hooks.errRefresh('no refresh_token or _endpoint')
    }
    throw Error('no refresh_token or _endpoint')
  }
  if (!!hooks.preRefresh) {
    console.debug('doRefresh preRefresh')
    hooks.preRefresh()
  }
  return axios
    .get(disco.token_endpoint, {
      params: {
        grant_type: 'refresh_token',
        refresh_token: token.refresh_token,
        client_id: oidcSettings.clientId,
      },
    })
    .then((response) => {
      token = response.data
      localStorage.fusionOidcToken = JSON.stringify(token)
      var user = store.getState().user
      if (user && !user.loggedIn) {
        user.loggedIn = true
      }
      store.dispatch({ type: 'set', token, user })
      setTimeout(doRefresh, token.expires_in * 0.9 * 1000)
      if (!!hooks.postRefresh) {
        console.debug('doRefresh postRefresh')
        hooks.postRefresh()
      }
      console.debug('DONE doRefresh', token)
      return token
    })
    .catch((error) => {
      console.error('ERROR doRefresh', error)
      if (!!hooks.errRefresh) {
        console.debug('doRefresh errRefresh')
        hooks.errRefresh(error)
      }
      throw error
    })
}

async function doUser() {
  console.debug('START doUser')
  if (!token || !token.access_token || !disco.userinfo_endpoint) {
    console.error('ERROR doUser no access_token or userinfo_endpoint')
    if (!!hooks.errUser) {
      console.debug('doUser errUser')
      hooks.errUser('no access_token or userinfo_endpoint')
    }
    throw Error('no access_token or userinfo_endpoint')
  }
  if (!!hooks.preUser) {
    console.debug('doUser preUser')
    hooks.preUser()
  }
  return axios
    .get(disco.userinfo_endpoint, {
      headers: {
        authorization: `Bearer ${token.access_token}`,
      },
    })
    .then((response) => {
      var user = response.data
      store.dispatch({ type: 'set', user: user })
      if (!!hooks.postUser) {
        console.debug('doUser postUser')
        hooks.postUser()
      }
      console.debug('DONE doUser', user)
      return user
    })
    .catch((error) => {
      var user = store.getState().user
      if (user && user.loggedIn) {
        user.loggedIn = false
        store.dispatch({ type: 'set', user })
      }
      console.error('ERROR doUser', error)
      throw error
    })
}

async function doRevoke(token_type = 'access_token') {
  console.debug('START doRevoke')
  if (['access_token', 'refresh_token'].indexOf(token_type) < 0) {
    console.error('ERROR doRevoke invalid token_type')
    if (!!hooks.errRevoke) {
      console.debug('doRevoke errRevoke')
      hooks.errRevoke('invalid token_type')
    }
    throw Error('invalid token_type')
  }
  if (!token || !token.access_token || !disco.revocation_endpoint) {
    const errmsg = 'no access_token or revocation_endpoint'
    console.error(`ERROR doRevoke ${errmsg}`)
    if (!!hooks.errRevoke) {
      console.debug('doRevoke errRevoke')
      hooks.errRevoke(errmsg)
    }
    throw errmsg
  }
  if (!!hooks.preRevoke) {
    console.debug('doRevoke preRevoke')
    hooks.preRevoke()
  }
  return axios
    .get(disco.revocation_endpoint, {
      params: {
        token: token[token_type],
        client_id: oidcSettings.clientId,
      },
      headers: {
        authorization: `Bearer ${token.access_token}`,
      },
    })
    .then((response) => {
      var revo = response.data
      store.dispatch({ type: 'set', user: null })
      if (!!hooks.postRevoke) {
        console.debug('doRevoke postRevoke')
        hooks.postRevoke()
      }
      console.debug('DONE doRevoke', revo)
      return revo
    })
    .catch((error) => {
      console.error('ERROR doRevoke', error)
      if (!!hooks.errRevoke) {
        console.debug('doRevoke errRevoke')
        hooks.errRevoke(error)
      }
      throw error
    })
}

const exports = {
  startIt,
  endIt,
  logout,
  doDisco,
  doAuthStart,
  doAuthEnd,
  doToken,
  doUser,
  doRevoke,
  disco,
  auth,
  token,
  oidcSettings,
  hooks,
}
export default exports

/*********************
 * Utility functions *
 *********************/

// generate a random string
function __randomString(length = 32) {
  const array = new Uint32Array(length)
  window.crypto.getRandomValues(array)
  return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join('')
}

// sha256 hash of a string
async function __sha256(plain) {
  // returns ArrayBuffer
  const encoder = new TextEncoder()
  const data = encoder.encode(plain)
  return await window.crypto.subtle.digest('SHA-256', data)
}

// base64url encoding of an ArrayBuffer
function __base64url(source) {
  // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  // (replace + with -, replace / with _, trim trailing =)
  return btoa(String.fromCharCode.apply(null, new Uint8Array(source)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

// generate pkce code_verifier and code_challenge
async function __pkce() {
  const code_verifier = __randomString(32)
  const code_challenge = __base64url(await __sha256(code_verifier))
  return { code_verifier, code_challenge }
}

// parse query string
function __parse_uri_query() {
  return Object.fromEntries(
    window.location.search
      .slice(1)
      .split('&')
      .map((x) => x.split('=', 2)),
  )
}
