import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Policy = React.lazy(() => import('./views/policy/Policy'))
const PolicyDocs = React.lazy(() => import('./views/policy_docs/PolicyDocs'))
const PolicyClaims = React.lazy(() => import('./views/policy_claims/PolicyClaims'))
const PolicyBroker = React.lazy(() => import('./views/policy_broker/PolicyBroker'))
const Mfa = React.lazy(() => import('./views/mfa/Mfa'))
const Training = React.lazy(() => import('./views/training/Training'))
const Checklists = React.lazy(() => import('./views/checklists/Checklists'))
const Templates = React.lazy(() => import('./views/templates/Templates'))
const Phishing = React.lazy(() => import('./views/phishing/Phishing'))
const Edr = React.lazy(() => import('./views/edr/Edr'))
const Intellishun = React.lazy(() => import('./views/intellishun/Intellishun'))
const Threatsweep = React.lazy(() => import('./views/threatsweep/Threatsweep'))
const Ztna = React.lazy(() => import('./views/ztna/Ztna'))
const Surface = React.lazy(() => import('./views/surface/Surface'))
const Vulnerability = React.lazy(() => import('./views/vulnerability/Vulnerability'))
const Backup = React.lazy(() => import('./views/backup/Backup'))
const Continuity = React.lazy(() => import('./views/continuity/Continuity'))
const Hunting = React.lazy(() => import('./views/hunting/Hunting'))
const Response = React.lazy(() => import('./views/response/Response'))
const Contact = React.lazy(() => import('./views/contact/Contact'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/policy', name: 'Policy Summary', element: Policy },
  { path: '/policy_docs', name: 'Policy Documents', element: PolicyDocs },
  { path: '/policy_claims', name: 'Policy Claims', element: PolicyClaims },
  { path: '/policy_broker', name: 'Policy Broker', element: PolicyBroker },
  { path: '/mfa', name: 'MFA', element: Mfa, exact: true },
  { path: '/training', name: 'Training', element: Training, exact: true },
  { path: '/checklists', name: 'Checklists', element: Checklists, exact: true },
  {
    path: '/checklists/:list',
    name: 'ViewChecklist',
    element: Checklists.ViewChecklist,
    exact: false,
  },
  { path: '/templates', name: 'Templates', element: Templates, exact: true },
  { path: '/phishing', name: 'Phishing', element: Phishing, exact: true },
  { path: '/edr', name: 'Edr', element: Edr, exact: true },
  { path: '/intellishun', name: 'Intellishun', element: Intellishun, exact: true },
  { path: '/threatsweep', name: 'Threatsweep', element: Threatsweep, exact: true },
  { path: '/ztna', name: 'Ztna', element: Ztna, exact: true },
  { path: '/surface', name: 'Surface', element: Surface, exact: true },
  { path: '/vulnerability', name: 'Vulnerability', element: Vulnerability, exact: true },
  { path: '/backup', name: 'Backup', element: Backup, exact: true },
  { path: '/continuity', name: 'Continuity', element: Continuity, exact: true },
  { path: '/hunting', name: 'Hunting', element: Hunting, exact: true },
  { path: '/response', name: 'Response', element: Response, exact: true },
  { path: '/contact', name: 'Contact', element: Contact, exact: true },
]

export default routes
