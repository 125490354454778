import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilCheck, cilDrop, cilPencil, cilPuzzle, cilStar } from '@coreui/icons'
import { CNavItem } from '@coreui/react'

const _nav = [
  /*
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  */
  {
    component: CNavItem,
    name: 'Policy',
    to: '/policy',
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    /*
    badge: {
      color: 'info',
      text: 'NEW',
    },
    */
  },
  /*
  {
    component: CNavGroup,
    name: 'Policy',
    icon: <CIcon icon={cilShieldAlt} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Documents',
        to: '/policy_docs',
      },
      {
        component: CNavItem,
        name: 'Claims',
        to: '/policy_claims',
      },
      {
        component: CNavItem,
        name: 'Broker',
        to: '/policy_broker',
      },
    ],
  },
  */
  /*
  {
    component: CNavGroup,
    name: 'Prevention',
    to: '/',
    icon: <CIcon icon={cilShieldAlt} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'MFA',
        to: '/mfa',
      },
      */
  {
    component: CNavItem,
    name: 'Training',
    to: '/training',
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Phishing',
    to: '/phishing',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'Templates',
    to: '/templates',
    icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Checklists',
    to: '/checklists',
    icon: <CIcon icon={cilCheck} customClassName="nav-icon" />,
  },

  /*
      {
        component: CNavItem,
        name: 'EDR',
        to: '/edr',
      },
      {
        component: CNavItem,
        name: 'IntelliShun',
        to: '/intellishun',
      },
      {
        component: CNavItem,
        name: 'ThreatSweep',
        to: '/threatsweep',
      },
      {
        component: CNavItem,
        name: 'ZTNA',
        to: '/ztna',
      },
      {
        component: CNavItem,
        name: 'Attack Surface',
        to: '/surface',
      },
      {
        component: CNavItem,
        name: 'Vulnerability Management',
        to: '/vulnerability',
      },
      */
  /*
    ],
  },
  {
    component: CNavGroup,
    name: 'Mitigation',
    to: '/',
    icon: <CIcon icon={cilBellExclamation} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Backup & Restore',
        to: '/backup',
      },
      {
        component: CNavItem,
        name: 'Business Continuity',
        to: '/continuity',
      },
      {
        component: CNavItem,
        name: 'Threat Hunting',
        to: '/hunting',
      },
      {
        component: CNavItem,
        name: 'Incident Response',
        to: '/incident',
      },
    ],
  },
  */
]

export default _nav
