import React from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import { Login } from '../views/pages'
import { useSelector } from 'react-redux'
import fusionOidc from '../fusionOidc'

const LoggedOut = () => {
  return (
    <div>
      <Login />
    </div>
  )
}

const LoggedIn = () => {
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <AppContent />
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

const DefaultLayout = () => {
  const token = useSelector((state) => state.token?.id_token)
  if (window.location.hostname === 'localhost') {
    console.log('DefaultLayout -- detected localhost')
    return <LoggedIn />
  }
  console.log(`DefaultLayout -- token:${token}`)
  if (!token) return <LoggedOut />

  const tval = JSON.parse(atob(token.split('.')[1]))
  const loggedIn = tval.exp > Date.now() / 1000
  console.log(`DefaultLayout -- loggedIn: ${loggedIn}  tval:${JSON.stringify(tval)}`)
  if (tval.exp > Date.now() / 1000) return <LoggedIn />

  fusionOidc.logout()
  return <LoggedOut />
}

export default DefaultLayout
