import React from 'react'
import { PropTypes } from 'prop-types'
import { CFooter } from '@coreui/react'
import Contact from 'src/views/contact/Contact'

const ContactModal = ({ show, handleClose }) => {
  console.log('ContactModal show:', show)
  return (
    <div className="w-100" style={{ display: show ? 'block' : 'none' }}>
      <div
        className={`modal-backdrop fade ${show ? 'show' : ''}`}
        style={{ display: show ? 'block' : 'none' }}
      />
      <div
        className={`w-50 m-5 modal fade ${show ? 'show' : ''}`}
        style={{ display: show ? 'block' : 'none' }}
      >
        <div className="border-secondary modal-content">
          <Contact />
          <div className="text-center">
            <button type="button" className="mb-3 btn btn-secondary" onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

ContactModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
}

const AppFooter = () => {
  const [showContactModal, setShowContactModal] = React.useState(false)

  const openContact = () => {
    console.log('openContact', showContactModal)
    setShowContactModal(true)
  }
  const closeContact = () => {
    console.log('closeContact', showContactModal)
    setShowContactModal(false)
  }

  console.log('AppFooter showContactModal:', showContactModal)

  return (
    <>
      <ContactModal show={showContactModal} handleClose={closeContact} />
      <CFooter>
        <div>
          <span className="mx-1">&copy; {new Date().getFullYear()}</span>
          <span className="mx-1">
            <a href="https://fusionmga.com" target="_blank" rel="noopener noreferrer">
              Fusion
            </a>
          </span>
          <span className="mx-5 p-1 border rounded bg-secondary" onClick={openContact}>
            Contact Us
          </span>
        </div>
      </CFooter>
    </>
  )
}

export default React.memo(AppFooter)
