import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import fusionOidc from 'src/fusionOidc'
import store from 'src/store'
import Loading from 'src/components/Loading'

const Login = () => {
  const navigate = useNavigate()
  const [didNavigate, setDidNavigate] = useState(false)
  const [navTo, setNavTo] = useState('')
  const [didProcessCode, setDidProcessCode] = useState(false)

  useEffect(() => {
    if (!didNavigate && navTo) {
      setDidNavigate(true)
      navigate(navTo)
    }
  }, [didNavigate, navTo, navigate])

  // If handling a login redirect, process it
  if (window.location.search.includes('code=')) {
    if (!didProcessCode) {
      setDidProcessCode(true)
      const opu = fusionOidc.hooks.postUser
      fusionOidc.hooks.postUser = () => {
        fusionOidc.hooks.postUser = opu
        var goTo = new URL(fusionOidc.oidcSettings.redirect_uri)
        goTo = goTo.pathname + goTo.search
        console.debug(`Processed login code, navigating to ${goTo}`)
        setNavTo(goTo)
      }
      fusionOidc.endIt()
    }
    return (
      <h1>
        Processing login...
        <Loading />
      </h1>
    )
  }

  // If already logged in, go home
  if (!!store.getState().user && !!store.getState().user.sub) {
    return <Navigate to="/" />
  }

  // If not logged in, kick it off
  fusionOidc.startIt()
  return (
    <h1>
      Redirecting to login page...
      <Loading />
    </h1>
  )
}

export default Login
