import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

// Containers
import DefaultLayout from './layout/DefaultLayout'

// Pages
import Login from './views/pages/login/Login'
// import Register from './views/pages/register/Register'
// import Page404 from './views/pages/page404/Page404'
// import Page500 from './views/pages/page500/Page500'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const App = () => {
  const query = Object.fromEntries(
    window.location.search
      .slice(1)
      .split('&')
      .map((x) => x.split('=', 2)),
  )
  if (query.error === 'server_error')
    return (
      <div>
        <h1>Server Error</h1>
        <hr />
        <p>There was an error, probably during login.</p>
        <p>{query.error_description.replaceAll('+', ' ')}</p>
      </div>
    )
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
