// TODO use redux-toolkit instead of raw redux
import { createStore } from 'redux'

var initialState = {
  sidebarShow: true,
}

const localState = localStorage.getItem('localState')
if (!!localState) initialState = JSON.parse(localState)

const changeState = (state = initialState, { type, ...rest }) => {
  /*
  console.log('changeState')
  console.log('    type:', type)
  console.log('    rest:', rest)
  console.log('    pre state:', state)
  */

  if (type.startsWith('@@redux/')) {
    console.log('Ignoring redux action', type)
  } else {
    switch (type) {
      case 'set':
        state = { ...state, ...rest }
        break
      default:
        console.error('Unknown action type: ' + type)
    }
  }

  localStorage.setItem('localState', JSON.stringify(state))
  return state
}

const store = createStore(changeState)
export default store
